import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import LoginPage from "pages/Areas/Register/LoginPage";
import Register from "pages/Areas/Register/Register";
import RegisterTeacher from "pages/Areas/Register/RegisterTeacher";
import RegisterTeacherOne from "pages/Areas/Register/RegisterTeacherSuccess";
import RegisterOne from "pages/Areas/Register/RegisterStudent";
import MyProfile from "pages/MyProfile";
import Home1 from "pages/StudentDashboard";
import Courses from "pages/Courses";
import CourseView from "pages/CourseView";
import LearningView from "pages/LearningView";
import LearningViewOne from "pages/LearningViewOne";
import LearningViewTwo from "pages/LearningViewTwo";
import LearningViewThree from "pages/LearningViewThree";
import TryAgainModal from "pages/TryAgainModal";
import LearningViewFour from "pages/LearningViewFour";
import TeachersDashboard from "pages/TeachersDashboard";
import Assignedcourses from "pages/Assignedcoursespage";
import Students from "pages/Students";
import Instructor from "pages/Instructor";
import Performancecourses from "pages/Performancecourses";
import Performancestock from "pages/Performancestock";
import Dashboard from "pages/Dashboard";
import Orders from "pages/Orders";
import Holdings from "pages/Holdings";
import Assignments from "pages/Assignments";
import StockView from "pages/StockView";
import Watchlist from "pages/Watchlist";
import BudgetSimulatorOne from "pages/BudgetSimulatorOne";
import BudgetSimulatorTwo from "pages/BudgetSimulatorTwo";
import BudgetSimulatorThree from "pages/BudgetSimulatorThree";
import BudgetSimulatorFour from "pages/BudgetSimulatorFour";
import CoursesPage from "pages/Courses";
import LearningViewTextPage from "pages/LearningViewText";
import LearningViewQuestionOne from "pages/LearningViewQuestionOne";
import LearningViewLessonPage from "pages/Areas/CourseContent/LearningViewLesson";
import ResendConfirmationPage from "pages/Areas/Register/ResendConfirmation";
import ResetPasswordPage from "pages/Areas/Register/ResetPassword";
import ConfirmEmailPage from "pages/Areas/Register/ConfirmEmail";
import ForgotPasswordPage from "pages/Areas/Register/ForgotPassword";
import ClassroomDetails from "pages/ClassroomDetails";
import CreateStudentAccounts from "pages/CreateStudentAccounts";
import CreateAssignment from "pages/CreateAssignment";
import StudentDashboardPage from "pages/StudentDashboard";
import TeachersDashboardPage from "pages/TeachersDashboard";
import TeacherCourseView from "components/TeacherCourseView";
import TeacherCourseCardsView from "pages/TeacherCourseCardsView";
import TeacherLearningViewLessonPage from "pages/Areas/CourseContent/TeacherLearningViewLesson";
import TeacherLearningViewQuizPage from "pages/Areas/CourseContent/TeacherLearningViewQuizPage";
import UnauthorizedPage from "pages/Unauthorized";
import Timeout from "pages/Timeout";
import HeatmapPage from "pages/Heatmap";
import EconomicCalendarPage from "pages/EconomicCalendar";
import BudgetSimLandingPage from "pages/BudgetSimulator/LandingPage";

import TopStoriesPage from "pages/TopStories";
import Forex from "pages/Forex";
import EarningsCalendar from "pages/EarningsCalendar";
import TopStocks from "pages/TopStocks";

import { Switch } from "react-router-dom";
import RegisterStudentSuccessPage from "pages/Areas/Register/RegisterStudentSuccess";
import { element } from "prop-types";
import LearningViewQuizPage from "pages/Areas/CourseContent/LearningViewQuizPage";
import TeacherDashboard from "pages/TeacherDashboard";
import CompleteLearningView from "components/LearningView/CompleteLearningView";
import StartupDashboard from "pages/StartupDashboard";
import StartupSimulatorSettings from "pages/SimulatorSettings";
import SimulatorSettings from "pages/SimulatorSettings";


const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <LoginPage /> },
    { path: "*", element: <NotFound /> },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "/register/teacher",
      element: <RegisterTeacher />,
    },
    {
      path: "/register/teacher/success",
      element: <RegisterTeacherOne />,
    },
    {
      path: "/register/student",
      element: <RegisterOne />,
    },
    {
      path: "/register/student/success",
      element: <RegisterStudentSuccessPage />,
    },
    {
      path: "/resendEmailConfirmation",
      element: <ResendConfirmationPage />
    },
    {
      path: "/confirmEmail",
      element: <ConfirmEmailPage />
    },
    {
      path: "/forgotPassword",
      element: <ForgotPasswordPage />
    },
    {
      path: "/resetPassword",
      element: <ResetPasswordPage />
    },
    {
      path: "myprofile",
      element: <MyProfile />,
    },
    {
      path: "home1",
      element: <Home1 />,
    },
    {
      path: "studenthome",
      element: <StudentDashboardPage />,
    },
    {
      path: "courses",
      element: <Courses />,
    },
    {
      path: "teachercourses",
      element: <TeacherCourseView />,
    },
    {
      path: "courses/course/:courseId/student/:studentId",
      element: <CourseView />
    },
    {
      path: "course/:courseId",
      element: <TeacherCourseCardsView />
    },
    {
      path: "courseview",
      element: <CourseView />,
    },
    // Course Content
    {
      // One with just the unit Id to default to the beginning
      path: "course/:courseId/unit/:unitId",
      element: <TeacherLearningViewLessonPage />,
    },
    {
      path: "course/:courseId/unit/:unitId/lesson/:lessonId",
      element: <TeacherLearningViewLessonPage />
    },
    {
      path: "course/:courseId/unit/:unitId/quiz/:quizId",
      element: <TeacherLearningViewQuizPage />
    },
    {
      // One with just the unit Id to default to the beginning
      path: "courses/course/:courseId/student/:studentId/unit/:unitId",
      element: <CompleteLearningView />,
    },
    {
      path: "courses/course/:courseId/student/:studentId/unit/:unitId/lesson/:lessonId",
      element: <CompleteLearningView />
    },
    {
      path: "courses/course/:courseId/student/:studentId/unit/:unitId/quiz/:quizId",
      element: <CompleteLearningView />
    },
    {
      path: "learningview",
      element: <LearningView />,
    },
    {
      path: "learningviewone",
      element: <LearningViewOne />,
    },
    {
      path: "learningviewtwo",
      element: <LearningViewTwo />,
    },
    {
      path: "learningviewthree",
      element: <LearningViewThree />,
    },
    {
      path: "tryagainmodal",
      element: <TryAgainModal />,
    },
    {
      path: "learningviewfour",
      element: <LearningViewFour />,
    },
    {
      path: "teachersdashboard",
      element: <TeachersDashboard />,
    },
    {
      path: "teacherhome",
      element: <TeacherDashboard />,
    },
    {
      path: "classroom/:classroomID",
      element: <ClassroomDetails />
    },
    {
      path: "classroom/:classroomID/createassignment",
      element: <CreateAssignment />
    },
    {
      path: "createstudents",
      element: <CreateStudentAccounts />
    },
    {
      path: "assignedcourses",
      element: <Assignedcourses />,
    },
    {
      path: "students",
      element: <Students />,
    },
    {
      path: "instructor",
      element: <Instructor />,
    },
    {
      path: "performancecourses",
      element: <Performancecourses />,
    },
    {
      path: "performancestock",
      element: <Performancestock />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "orders",
      element: <Orders />,
    },
    {
      path: "holdings",
      element: <Holdings />,
    },
    {
      path: "heatmap",
      element: <HeatmapPage />,
    },
    {
      path: "topstories",
      element: <TopStoriesPage />,
    },
    {
      path: "economiccalendar",
      element: <EconomicCalendarPage />,
    },
    {
      path: "assignments",
      element: <Assignments />,
    },
    {
      path: "stockview/:stockSymbol",
      element: <StockView />,
    },
    {
      path: "watchlist",
      element: <Watchlist />,
    },
    {
      path: "forex",
      element: <Forex />,
    },
    {
      path: "earningscalendar",
      element: <EarningsCalendar />,
    },
    {
      path: "topstocks",
      element: <TopStocks />,
    },
    {
      path: "budgetsimulator",
      element: <BudgetSimLandingPage />,
    },
    {
      path: "budgetsimulatorone",
      element: <BudgetSimulatorOne />,
    },
    {
      path: "budgetsimulatortwo",
      element: <BudgetSimulatorTwo />,
    },
    {
      path: "budgetsimulatorthree",
      element: <BudgetSimulatorThree />,
    },
    {
      path: "budgetsimulatorfour",
      element: <BudgetSimulatorFour />,
    },
    {
      path: "pages/Courses",
      element: <CoursesPage />,
    },
    {
      path: "pages/Classes",
      element: <CoursesPage />,
    },
    {
      path: "pages/StockView",
      element: <StockView />,
    },
    {
      path: "LearningViewText",
      element: <LearningViewTextPage />,
    },
    {
      path: "learningviewquestionone",
      element: <LearningViewQuestionOne />,
    },
    {
      path: "unauthorized",
      element: <UnauthorizedPage />,
    },
    ,
    {
      path: "timeout",
      element: <Timeout />,
    },
    {
      path: "startup",
      element: <StartupDashboard />,
    },
    {
      path: "classroom/:classroomID/simulatorsettings",
      element: <SimulatorSettings />
    },
  ]);

  return element;
};

export default ProjectRoutes;
