import { Text, Img, Button, Input, CheckBox } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { fetchInfo } from "SimulatorAPIService";
import { UserContext } from "UserContext";
import React, { useState, useContext } from "react";
import Lottie from "lottie-react";
import successAnimation from "assets/animations/success-checkmark.json";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const Form = () => {
    // State variables
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberme, setRememberme] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    // New state variables for loading and success status
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { userInfo, setUserInfo } = useContext(UserContext);

    const navigate = useNavigate();

    const handleClickRegister = () => {
        navigate("/Register");
    };

    const handleClickForgotPassword = () => {
        navigate("/forgotPassword");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email and password
        if (!email || !password) {
            setError("Please fill in all fields.");
            return;
        }

        // Clear error message and start loading
        setError("");
        setIsLoading(true);

        try {
            const response = await fetch(`${API_ENDPOINT}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            if (!response.ok) {
                throw new Error("Error Logging In.");
            }

            // Parse the response to get the access token
            const data = await response.json();

            // Store the access token
            if (rememberme) {
                localStorage.setItem("accessToken", data.accessToken);
            } else {
                sessionStorage.setItem("accessToken", data.accessToken);
            }

            // Fetch user info with the access token
            const userData = await fetchInfo();

            setUserInfo(userData);

            // Indicate success and stop loading
            setIsSuccess(true);
            setIsLoading(false);

            // Delay navigation to show the success animation
            setTimeout(() => {
                if (userData.role === "Student") {
                    window.location.href = "/studenthome";
                } else {
                    window.location.href = "/teacherhome";
                }
            }, 2000); // Adjust the delay as needed
        } catch (error) {
            console.error(error);
            setError("Error Logging in.");
            setIsLoading(false);
        }
    };


    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    return (
        <div className="relative max-w-[600px] w-[80%]">
            {/* The Form */}
            <form onSubmit={handleSubmit}>
                {/* Error Message */}
                <div className="text-red-500 text-sm">
                    {error !== "" ? error : ""}
                </div>

                {/* Form Fields */}
                <div className="flex flex-col gap-8">
                    {/* Email Field */}
                    <div className="flex flex-col items-start gap-[5px]">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            Username or Email
                        </Text>
                        <Input
                            size="xl"
                            shape="round"
                            id="email"
                            name="email"
                            value={email}
                            onChange={setEmail}
                            onKeyDown={handleKeyDown}
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>

                    {/* Password Field */}
                    <div className="flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            Password
                        </Text>
                        <Input
                            size="xl"
                            shape="round"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            value={password}
                            onChange={setPassword}
                            onKeyDown={handleKeyDown}
                            suffix={
                                <Img
                                    src={
                                        showPassword
                                            ? "/images/img_eye.svg"
                                            : "/images/img_eyeoff.svg"
                                    }
                                    alt={showPassword ? "eye" : "eye-off"}
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="ml-4 h-[16px] w-[16px] cursor-pointer"
                                />
                            }
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>

                    {/* Remember Me and Forgot Password */}
                    <div className="flex justify-between gap-5">
                        <CheckBox
                            label="Remember Password"
                            id="rememberme"
                            name="rememberme"
                            checked={rememberme}
                            onChange={setRememberme}
                            className="gap-3 self-start p-px font-inter text-base text-blue_gray-500"
                        />
                        <Text
                            size="7xl"
                            as="p"
                            className="self-end !font-inter !text-blue-A200"
                        >
                            <a
                                className="cursor-pointer"
                                onClick={handleClickForgotPassword}
                            >
                                Forgot Password?
                            </a>
                        </Text>
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex gap-8 self-start justify-center w-full mt-8">
                    <Button
                        size="5xl"
                        shape="round"
                        className="min-w-[367px] bg-primary font-semibold shadow-sm sm:px-5 flex items-center justify-center hover:bg-blue-500"
                        disabled={isLoading} // Disable button when loading
                    >
                        {isSuccess ? "Success" : "Login"} {/* Text changes on success */}

                    </Button>
                </div>

                {isSuccess && (
                    <div className="justify-center flex w-full pt-4">
                        <Lottie
                            animationData={successAnimation}
                            loop={false}
                            style={{ width: 48, height: 48 }} // Adjust size as needed
                        />
                    </div>
                )}
            </form>
        </div>
    );
};

export default Form;
