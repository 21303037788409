import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Heading, Img, Input } from "../../components";
import { CloseSVG } from "../../assets/images";
import { fetchStockInfo, searchStocks } from "SimulatorAPIService";

const StockSearch = () => {
    const [searchBarValue, setSearchBarValue] = useState("");
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [recentSearches, setRecentSearches] = useState(JSON.parse(localStorage.getItem('recentSearches')) || []);
    const [searchStock, setSearchStock] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const storedSearches = JSON.parse(localStorage.getItem('recentSearches'));
        if (storedSearches) {
            setRecentSearches(storedSearches);
        }
    }, []);

    const handleClearRecentSearches = () => {
        localStorage.clear();
        setRecentSearches([]);
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            try {
                const data = await searchStocks(searchBarValue);
                setFilteredStocks(data);
                setShowPopup(true);
            } catch (error) {
                if (error.message.includes('401')) {
                    navigate('/timeout'); // Navigate to timeout page if 401 response
                } else {
                    console.error(error);
                }
            }
        }
    };

    const handleLinkClick = async (stock) => {
        try {
            const data = await fetchStockInfo(stock.symbol);

            const newSearch = {
                symbol: data.profile?.symbol,
                previousClose: data.summary?.previousClose,
                name: data.profile?.name,
                current: data.summary?.current,
                change: data.summary?.change,
                percentChange: ((data.summary?.current - data.summary?.previousClose) / data.summary?.previousClose) * 100,
            };

            const updatedSearches = [newSearch, ...recentSearches.filter(search => search.symbol !== newSearch.symbol)];
            setRecentSearches(updatedSearches);
            localStorage.setItem('recentSearches', JSON.stringify(updatedSearches));
            setShowPopup(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="w-full">
            <div className="border-b relative">
                <Input
                    size=""
                    name="search"
                    placeholder="Search Stock eg. AAPL, MSFT..."
                    value={searchBarValue}
                    onChange={setSearchBarValue}
                    onKeyPress={handleKeyPress}
                    prefix={
                        <div className=" pr-2">
                            <Img
                                src="/images/img_search_blue_gray_100_13x13.svg"
                                alt="search"
                                className="cursor-pointer"
                            />
                        </div>
                    }
                    suffix={
                        searchBarValue?.length > 0 ? (
                            <CloseSVG onClick={() => {
                                setSearchBarValue("");
                                setShowPopup(false);
                            }}
                                fillColor="#d3dbe4ff" />
                        ) : null
                    }
                    className="rounded-t-lg tracking-[0.50px] !text-blue_gray-100 p-4 sm:pr-5"
                />

            </div>

            {/* Recent Searches */}
            <div className="px-2 md:pb-5 mt-4 gap-4">
                {showPopup && (
                    <div>
                        <div className=" bg-white rounded-lg mt-1 z-10">
                            <Heading size="9xl" as="h1">
                                Search Results
                            </Heading>
                            {filteredStocks.length > 0 ? (
                                filteredStocks.map(stock => (
                                    <Link
                                        to={`/stockview/${stock.symbol}`}
                                        key={stock.symbol}
                                        className="flex justify-between items-center p-4 bg-white border-b cursor-pointer"
                                        onClick={() => handleLinkClick(stock)}
                                    >
                                        <div>{stock.name} ({stock.symbol})</div>
                                    </Link>
                                ))
                            ) : (
                                <div className="p-4">No results found</div>
                            )}
                        </div>

                        <div className="w-full border-b border-solid border-blue_gray-100 p-4"></div>
                    </div>
                )}

                <div className="flex justify-between px-2 pb-[13px] pt-3.5">
                    <Heading size="9xl" as="h1">
                        Recent Searches
                    </Heading>
                    <button onClick={handleClearRecentSearches} className="text-gray-600 text-sm px-3 py-1 rounded">
                        CLEAR
                    </button>
                </div>

                <div className="flex flex-col gap-4">
                    {recentSearches.map(stock => (
                        <Link
                            to={`/stockView/${stock.symbol}`}
                            key={stock.symbol}
                            className="flex justify-between items-center p-4 bg-white border rounded-lg cursor-pointer"
                        >
                            <div>
                                <div>{stock.symbol}</div>
                            </div>
                            <div className="flex gap-4 text-right">
                                <span className={`ml-2`}>
                                    {Number(stock.percentChange).toFixed(2)}%
                                </span>
                                <div
                                    className={
                                        stock.previousClose > stock.current ? "text-red-500 flex" : "text-green-500 flex"
                                    }
                                >
                                    <img
                                        src={stock.previousClose > stock.current ? "/images/img_arrow_down_red_500.svg" : "/images/img_arrow_up.svg"}
                                        alt={stock.previousClose > stock.current ? 'Down arrow' : 'Up arrow'}
                                        className="h-5"
                                    />
                                    ${stock.current}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StockSearch;
