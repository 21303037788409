import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img } from "components";
import StudentSidebar from "components/StudentSidebar";
import { useNavigate } from 'react-router-dom';
import HomeDate from "components/HomeDate";
import { IconClock, IconBulb, IconFlag } from "@tabler/icons-react";
import { Button } from "components";

export default function BudgetSimLandingPage() {

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>

            <div className="flex w-full h-screen overflow-hidden m-auto">
                <div><StudentSidebar /></div>

                <div className="flex flex-col w-full m-4 gap-4">
                    <HomeDate className="w-full px-4 rounded-lg" />

                    <div className="flex flex-col rounded-xl border p-6 gap-12">


                        <div className="flex gap-4">

                            <div className="w-[50%]">
                                <div className="flex flex-col gap-4">

                                    <div className="flex flex-col gap-2">
                                        <h2 className="font-bold text-3xl">Budget Simulator</h2>

                                        <div className="flex items-center">
                                            <IconClock className="flex h-[12px]" />
                                            <p className="text-sm">Duration: 30 Minutes</p>
                                        </div>
                                    </div>

                                    <p className="leading-relaxed">The Intertwined Personal Budget Simulator is an interactive educational tool designed for you to learn essential financial skills through a realistic and engaging game environment. You will manage a virtual budget, make financial decisions, and face real-life financial scenarios to develop practical money management skills. The simulator aims to enhance financial literacy, preparing students for future financial independence.</p>
                                </div>
                            </div>

                            <div className="w-[50%]">
                                <Img
                                    src="images/img_adobestock_124712720.png"
                                    alt="adobestock"
                                    className="h-full rounded-lg"
                                />
                            </div>
                        </div>

                        <div className="flex gap-4">

                            <div className="flex flex-col w-[50%] rounded-2xl shadow-md border p-6 gap-4">
                                <h3 className="flex gap-2 text-lg font-semibold"><IconBulb />Learning Objectives</h3>

                                <ul className="pointer-events-none list-disc list-inside flex flex-col gap-2 text-sm">
                                    <li>Learning to build and maintain a credit score</li>
                                    <li>Budgeting a paycheck</li>
                                    <li>Tracking expenses and income</li>
                                    <li>Saving for short-term and long-term goals</li>
                                    <li>Managing debt and loans</li>
                                    <li>Understanding taxes and deductions</li>
                                    <li>Making informed financial decisions</li>
                                    <li>Maintaining a wellness meter that balances financial and personal well-being</li>
                                </ul>

                            </div>

                            <div className="flex flex-col w-[50%] rounded-2xl shadow-md border p-6 gap-4">
                                <h3 className="flex gap-2 text-lg font-semibold"><IconFlag />Scenario Goals</h3>

                                <ul className="pointer-events-none list-disc list-inside flex flex-col gap-2 text-sm">
                                    <li>Successfully manage a monthly budget without incurring debt</li>
                                    <li>Achieve and maintain a high credit score</li>
                                    <li>Save a specific amount of money within a set timeframe</li>
                                    <li>Make informed financial decisions that positively impact both financial health and personal wellness</li>
                                    <li>Understand the consequences of financial choices through realistic scenarios and challenges</li>
                                </ul>

                            </div>
                        </div>

                        <div className="w-full flex justify-center">
                            <Button
                                size="5xl"
                                variant="outline"
                                shape="round"
                                className="w-[20%] min-w-[400px] bg-blue-500 font-roboto font-medium tracking-[0.10px] sm:px-5 transform transition-transform duration-300 ease-in-out hover:scale-105"
                            >
                                <span className="text-white-A700">
                                    Start Your Personal Budget Simulator
                                </span>
                            </Button>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
